<template>
  <div class="superManager-report-container">
    <ADXSettlementNav />
    <div class="report-item">
      <span>业务日期：</span>
      <el-date-picker
        v-model="months"
        placement="bottom-start"
        type="monthrange"
        range-separator="至"
        start-placeholder="开始月份"
        value-format="yyyy-MM"
        @change="changeMonths"
        :clearable="true"
        end-placeholder="结束月份"
        :picker-options="pickerOptions"
      >
      </el-date-picker>
      <a-select
        class="search_item"
        :allowClear="true"
        placeholder="请选择结算状态"
        v-model="query.sts"
        @change="handleAutoSearch"
      >
        <a-select-option :value="1">待申请</a-select-option>
        <a-select-option v-if="cur !== 1" :value="2">待上传发票</a-select-option>
        <a-select-option :value="3">审核失败</a-select-option>
        <a-select-option :value="4">结算中</a-select-option>
        <a-select-option :value="5">结算完成</a-select-option>
        <a-select-option :value="6">结算失败</a-select-option>
      </a-select>
      <a-input
        class="search_item"
        placeholder="请输入公司名称或账户名称"
        style="width: 280px"
        allowClear
        v-model.trim="query.name"
        @input="changeInputValue"
      />
    </div>
    <div class="super-manager-table">
      <a-table
        class="m_table_cust"
        size="middle"
        :pagination="false"
        :rowKey="(record, index) => index"
        :columns="columns"
        :data-source="dataList"
        tableLayout="fixed"
        :loading="isLoading"
        bordered
      >
        <!-- 状态 1:待申请 2: 待上传发票 3: 审核失败 4:结算中 5:结算完成 6:结算失败 -->
        <span slot="sts" slot-scope="sts">
          <a-tag
            :color="
              +sts === 1
                ? 'orange'
                : +sts === 2
                  ? 'red'
                  : +sts === 3
                    ? 'green'
                    : +sts === 3
                      ? 'yellow'
                      : +sts === 4
                        ? 'pink'
                        : +sts === 5
                          ? 'blue'
                          : 'purple'
            "
          >
            {{
              +sts === 1
                ? '待申请'
                : +sts === 2
                  ? '待上传发票'
                  : +sts === 3
                    ? '审核失败'
                    : +sts === 4
                      ? '结算中'
                      : +sts === 5
                        ? '结算完成'
                        : '结算失败'
            }}
          </a-tag>
        </span>
        <div slot="action" slot-scope="record">
          <a-button size="small" type="link" @click="exportReport(record)">
            下载结算单
          </a-button>
        </div>
        <span slot="amount" slot-scope="amount, record">
          <a-button size="small" type="link" @click="getDetail(record)">
            <span style="text-decoration: underline">{{ moneyType + amount }}</span>
          </a-button>
        </span>
      </a-table>
      <a-pagination
        class="pagination"
        v-if="dataList.length > 0"
        show-size-changer
        :current="query.page"
        :total="total"
        @change="handleChangePage"
        :show-total="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        :pageSize="query.limit"
      />
      <m-empty v-else style="height: 341px" imgHeight="176px">
        <template #description> 暂时没有任何数据 </template>
      </m-empty>
    </div>
    <a-modal
      title="修改结算状态"
      :visible="showStsVisible"
      @ok="handleSubmit"
      @cancel="showStsVisible = false"
      width="750px"
    >
      <div class="adxForm">
        <a-form-model ref="ruleForm" :model="formquery" :rules="rules" v-bind="layout">
          <div class="form-flex-wrap">
            <div class="wrap-box">
              <div class="wrap-box-label">公司名称:</div>
              <div class="wrap-box-desc">{{ formquery.developerName }}</div>
            </div>
            <div class="wrap-box">
              <div class="wrap-box-label">账户名称:</div>
              <div class="descShort">{{ formquery.userName }}</div>
            </div>
            <div class="wrap-box">
              <div class="wrap-box-label">登录邮箱:</div>
              <div class="wrap-box-desc">{{ formquery.loginId }}</div>
            </div>
            <div class="wrap-box">
              <div class="wrap-box-label">结算金额:</div>
              <div class="descShort">{{ moneyType + formquery.amount }}</div>
            </div>
          </div>
          <!-- 结算状态 -->
          <a-form-model-item
            style="margin-top: 12px"
            label="结算状态"
            prop="sts"
            :rules="[{ required: true, message: '请选择结算状态', trigger: 'change' }]"
          >
            <a-select
              style="margin-left: -14px"
              placeholder="请选择结算状态"
              showSearch
              allowClear
              :filter-option="filterOption"
              v-model="formquery.sts"
              @change="changeSts"
            >
              <a-select-option v-for="item in stsList" :key="+item.id" :value="+item.id">{{
                item.name
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <!-- 失败原因 -->
          <a-form-model-item
            v-if="+formquery.sts === 3 || +formquery.sts === 6"
            label="失败原因"
            prop="remark"
            :rules="[{ required: true, message: '请输入失败原因', trigger: 'blur' }]"
          >
            <div style="position: relative">
              <a-textarea
                style="margin-left: -14px; height: 150px"
                v-model="formquery.remark"
                @blur="handleBlur"
                placeholder="请输入失败原因："
              />
              <span style="position: absolute; bottom: 5px; right: 25px; color: gray"> 50字以内 </span>
            </div>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
    <detailPart
      v-if="showDetailVisible"
      :visible="showDetailVisible"
      :detailQbject="detailQbject"
      @modalCancel="showDetailVisible = false"
    />
  </div>
</template>

<script>
import { adxSettleReport, adxSettleUpdateSts } from '@/apiForManage/financialManagementA'
import { mapState } from 'vuex'
import { numFormat } from '@/utils/dealNumber'
import moment from 'moment'
import ADXSettlementNav from './ADXSettlementNav'
import request from '@/utils/request'
import excel from '@/utils/excel'

const detailPart = () => import(/* webpackChunkName: "dialog" */ './detailPart')
export default {
  name: 'SuperManager',
  components: { detailPart, ADXSettlementNav },
  data () {
    return {
      // 表格参数
      query: {
        dateStart: '',
        dateEnd: '',
        name: '',
        sts: undefined,
        // contractSts: undefined,
        page: 1,
        limit: 10
      },
      detailQbject: {},
      // 弹窗参数
      formquery: {
        sts: undefined,
        remark: ''
      },
      rules: {},
      layout: {
        labelCol: { span: 5 },
        wrapperCol: { span: 16 }
      },
      // 状态列表
      stsList: [
        { name: '未发起结算', id: '1' },
        { name: '已发起结算', id: '2' },
        { name: '发票错误(审核失败)', id: '3' },
        { name: '收到发票(结算中)', id: '4' },
        { name: '已付款(结算完成)', id: '5' },
        { name: '付款失败(结算失败)', id: '6' }
      ],
      total: 0,
      // 表格数据
      dataList: [],
      isLoading: false,
      // 结算修改弹窗
      showStsVisible: false,
      showDetailVisible: false,
      months: [],
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() > Date.now()
        }
      },
      columns: [
        {
          title: '业务时间',
          dataIndex: 'date',
          width: '150px',
          scopedSlots: {
            customRender: 'date'
          }
        },
        {
          title: '公司名称',
          dataIndex: 'developerName',
          scopedSlots: {
            customRender: 'developerName'
          },
          ellipsis: true
        },
        // {
        //   title: '账户名称',
        //   dataIndex: 'userName',
        //   width: '150px',
        //   scopedSlots: {
        //     customRender: 'userName'
        //   }
        // },
        {
          title: '登录邮箱',
          dataIndex: 'loginId',
          scopedSlots: {
            customRender: 'loginId'
          },
          ellipsis: true
        },
        {
          title: '结算金额',
          dataIndex: 'amount',
          scopedSlots: {
            customRender: 'amount'
          }
        },
        {
          title: '结算状态',
          dataIndex: 'sts',
          width: '150px',
          scopedSlots: {
            customRender: 'sts'
          }
        },
        {
          title: '操作',
          width: '150px',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ]
    }
  },
  created () {
    // 初始化时间
    // this.initMonth(2, 0)
    this.handleAutoSearch()
  },
  computed: {
    ...mapState({
      isAdmin: (state) => state.user.isAdmin,
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title,
      cur: (state) => state.user.cur,
      moneyType: (state) => +state.user.cur === 1 ? '$' : '¥'
    })
  },
  watch: {
    // 失败原因不允许输入空格
    'formquery.remark': {
      handler (newValue, oldValue) {
        if (!newValue) {
          return
        }
        this.formquery.remark = newValue.replace(/\s+/g, '')
        if (this.formquery.remark.length > 50) {
          this.$message.warning('请控制在50字以内')
          this.formquery.remark = oldValue
        }
      }
    }
  },
  methods: {
    // 导出表格接口函数
    downloadReport (query) {
      return request.post('adxSettle/download', query, {
        responseType: 'blob'
      })
    },
    // 导出报表
    async exportReport (record) {
      if (this.contractSts === 0) {
        this.$message.warning('请先联系客服签署结算合同')
      } else {
        try {
          const res = await this.downloadReport({
            id: record.id
          })
          await excel(res, `北京前呈无限科技有限公司结算账单-${record.date}.pdf`)
        } catch (e) {
          console.log(e, '导出pdf错误信息')
        }
      }
    },
    // 失败原因效验
    handleBlur () {
      this.$nextTick(() => {
        this.$refs.ruleForm.validateField('remark')
      })
    },
    // 月份初始值
    initMonth (start, end) {
      const startMonth = moment().add(-start, 'M').format('YYYY-MM')
      const endMonth = moment().add(-end, 'M').format('YYYY-MM')
      this.months = [startMonth, endMonth]
      this.query.dateEnd = endMonth
      this.query.dateStart = startMonth
    },
    // 改变月份
    changeMonths (e) {
      this.query.dateStart = e ? e[0] : undefined
      this.query.dateEnd = e ? e[1] : undefined
      this.handleAutoSearch()
    },
    // 输入
    changeInputValue () {
      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.handleAutoSearch()
      }, 1000)
    },
    // 自动查询
    handleAutoSearch () {
      this.query = {
        ...this.query,
        page: 1,
        limit: this.query.limit
      }
      this.getDataList()
    },
    // 获取列表数据
    async getDataList () {
      this.isLoading = true
      const { data = {} } = await adxSettleReport(this.query)
      const { total = 0, items = [] } = data
      this.total = total
      this.dataList = items
      this.dataList.map((item) => {
        item.amount = numFormat(item.amount, 3, '')
      })
      this.isLoading = false
    },
    // 改变页数
    handleChangePage (page) {
      this.query.page = page
      this.getDataList()
    },
    // 改变limit
    onShowSizeChange (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.getDataList()
    },
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    // 改变结算状态
    changeSts (e) {
      // 切换状态时候，原因为空
      if (+e !== 3 && +e !== 6) {
        this.formquery.remark = ''
      }
    },
    // 结算详情
    async getDetail (record) {
      this.detailQbject = record
      this.showDetailVisible = true
    },
    // 弹窗提交
    async handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const resx = await adxSettleUpdateSts({
            id: this.formquery.id,
            sts: this.formquery.sts,
            remark: this.formquery.remark
          })
          if (resx.code === 200) {
            this.$message.success('修改结算状态成功')
            this.showStsVisible = false
            this.handleAutoSearch()
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.superManager-report-container {
  border-radius: 5px;
  margin: 0 10px;
  padding: 5px 18px;
  ::v-deep {
    .report-item {
      background-color: #fff;
      margin-top: 20px;
      padding: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 77px;
      border-radius: 5px 5px 0 0;

      .search_item {
        width: 220px;
        display: inline-block;
        margin-left: 10px;
      }
    }
  }

  .super-manager-table {
    border-radius: 0 0 5px 5px;
    min-height: 50vh;
    padding: 20px;
    background-color: #fff;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-top: calc(@smallMargin - @thPadding);
    .pagination {
      margin: 10px;
    }
  }
}
.adxForm {
  height: 45vh;
  padding: 10px;
  overflow-y: auto;
  .form-flex-wrap {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    white-space: nowrap;
    .wrap-box {
      padding: 20px 10px;
      width: 300px;
      display: flex;
      justify-content: flex-start;
      color: #4b4b4b;
      .wrap-box-label {
        text-align: right;
        padding: 0 10px;
      }
      .wrap-box-desc {
        text-align: left;
        width: 300px;
        padding: 0 10px;
      }
      .descShort {
        text-align: left;
        width: 300px;
      }
    }
  }
}
</style>
